.counter {
  border: 2px solid rgb(237, 237, 237);
  display: flex;
  align-items: center;
  height: 2.25rem;
  width: max-content;
  @media (max-width: 767px){
    height: 26px;
  }
  .minus {
    width: 48px;
    min-width: 48px;
    height: 36px;
    @media (max-width: 767px){
      width: 32px;
      min-width: 32px;
      height: 26px;
    }
  }
  .count {
    background: rgb(237, 237, 237);
    color: #000;
    font-size: 24px;
    min-width: 48px;
    width: 48px;
    height: 36px;
    text-align: center;
    @media (max-width: 767px){
      width: 32px;
      min-width: 32px;
      height: 26px;
      font-size: 18px;
    }
  }
  .plus {
    width: 48px;
    min-width: 48px;
    height: 36px;
    @media (max-width: 767px){
      width: 32px;
      min-width: 32px;
      height: 26px;
    }
  }
}
