.cart {
  // min-height: 400px;
  // display: flex;
  position: relative;
  transition: box-shadow 0.3s ease 0s;
  padding: 20px;
  margin: 10px 0px;
  box-shadow: none;
  width: unset;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 10px 7px;
    min-height: auto;
  }
  &:hover {
    color: rgb(228, 69, 66) !important;
    box-shadow: rgba(0, 0, 0, 0.18) 0px -3px 8px;
  }
  .favorite-icon {
    position: absolute;
    top: 12px;
    width: 32px;
    height: 32px;
    right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    @media (max-width: 767px){
      top: 0;
      right: 0;
    }
  }
  .favorite {
    background-color: rgb(255, 255, 255);
    color: rgb(153, 153, 153);
  }
  .favorited {
    background: rgb(228, 69, 66) !important;
    color: #fff !important;
  }
 
  .cart-item {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    @media (max-width: 767px) {
      justify-content: stretch;
      height: auto;
    }
    height: 363px;
  }
 
  .cart-image {
    max-height: 235px;
    height: 165px;
    margin-bottom: 12px;
    width: 100%;
    // @media (max-width: 767px) {
    //   height: 65px;
    //   margin-bottom: 8px;
    // }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .cart-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
    font-weight: 400;
    line-height: 1.5;
    // margin-top: 1rem;
    min-height: 50px;
    margin-bottom: 12px;
    line-height: 1.57;
    letter-spacing: 1.57;
    height: auto;
    @media (max-width: 767px) {
      overflow: hidden;
      //white-space: nowrap;
      text-overflow: ellipsis;
      width: 160px;
      margin-top: 0;
      margin-bottom: 6px;
    }
  }

  .rassrochka {
    position: absolute;
    bottom: 72px;
    font-weight: 600;
    font-size: 16px;
    border: 4px solid rgb(254, 238, 0);
    border-radius: 12px;
    color: #333 !important;
    // margin-bottom: 10px;
    text-align: center;
    width: 80%;
    @media (max-width: 767px){
      display: none;
    }
  }

  .cart-action {
    // margin-top: auto;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    align-items: flex-end;
    justify-content: space-between;

    .cart-basket {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      min-width: 44px;
      background: rgb(254, 238, 0);
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background: rgb(255, 206, 57);
      }
    }

    .cart-price {
      font-size: 18px;
      @media (max-width: 767px){
        font-size: 14px;
      }
    }
  }
}
