.category-slider {
  &:hover .slick-arrow {
    opacity: 1 !important;
  }
  .category-box {
    background: rgb(245, 245, 246);
    padding: 14px 16px;
    margin: 0 8px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    column-gap: 16px;
    @media (max-width: 767px){
      column-gap: 4px;
      padding: 4px;
      margin: 0 4px;
    }
    &:hover > div {
      color: rgb(228, 69, 66);
    }
    img {
      height: 96px;
      width: 96px;
      min-width: 96px;
      object-fit: contain;
      @media (max-width: 767px){
        height: 56px;
        width: 56px;
        min-width: 56px;
      }
    }
    > div {
      text-transform: capitalize;
      @media (max-width: 767px){
        font-size: 14px;
        overflow: hidden;
      }
    }
  }
  .slick-arrow {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 10;
    opacity: 0;
    fill: rgb(177, 177, 177);
    background: #fff;
    transition: all 0.3s;
  }
  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
}
