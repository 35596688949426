.basket-cart {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid rgb(237, 237, 237);
  .basket-image {
    width: 200px;
    min-width: 200px;
    height: 130px;
    @media (max-width: 767px) {
      width: 100px;
      min-width: 100px;
      height: auto;
      padding: 0 12px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @media (max-width: 767px) {
        height: auto;
      }
    }
  }
  .basket-text {
    padding: 5px 0;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .basket-name {
    display: block;
    font-family: "Jost Medium";
    font-size: 24px;
    color: #333;
    line-height: 1.2;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .basket-price {
    font-size: 24px;
    font-family: "Jost Bold";
    line-height: 1.2;
    @media (max-width: 767px) {
      font-size: 16px;
      margin-top: 4px;
    }
  }
  .basket-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .basket-remove {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    color: #999999;
  }
}
.price-box {
  background: rgb(247, 247, 247);
  padding-top: 1.5rem;
}
.payment-box {
  background: rgb(245, 245, 246);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  cursor: pointer;
  .css-hyxlzm {
    color: rgb(254, 238, 0) !important;
  }
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: rgb(254, 238, 0) !important;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: "Jost" !important;
  font-size: 15px !important;
  color: #333 !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.12) !important;
}
.checkout-section {
  .left {
    @media (max-width: 1023px) {
      grid-column-start: 1;
      grid-row-start: 2;
    }
  }
}
.checkout-cart {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 1.5rem;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .checkout-image {
    min-width: 80px;
    width: 80px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .checkout-name {
    line-height: 1.57;
    margin-left: 1rem;
  }
  .checkout-price {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 1rem;
    @media (max-width: 767px) {
    }
    .price {
      font-size: 20px;
    }
  }
  //.checkout-text {
  //  @media  {
  //
  //  }
  //}
}
