.recommendations {
  .slick-next {
    top: -20px;
    right: 0;
    width: 24px;
    height: 24px;
    &:hover {
      color: red !important;
    }
  }
  .slick-prev {
    top: -20px;
    left: auto;
    right: 35px;
    width: 24px;
    height: 24px;
    &:hover {
      color: red !important;
    }
  }
}

.similar-carts {
  .slick-next {
    top: -20px;
    right: 0;
    width: 24px;
    height: 24px;
    &:hover {
      color: red !important;
    }
  }
  .slick-prev {
    top: -20px;
    left: auto;
    right: 35px;
    width: 24px;
    height: 24px;
    &:hover {
      color: red !important;
    }
  }
}
