.filter {
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded,
  .css-67l5gl.Mui-expanded {
    margin: 0 !important;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root,
  .css-67l5gl {
    box-shadow: none !important;
    border: 0.5px solid rgb(220, 220, 220) !important;
  }
  .css-ahj2mt-MuiTypography-root,
  .css-9l3uo3 {
    font-family: "Jost Medium" !important;
  }
  .all-category {
    .item {
      display: flex;
      padding: 15px 16px !important;
      border-top: 0.5px solid rgb(220, 220, 220) !important;
      transition: all 0.4s;
      &:hover {
        background: rgb(254, 238, 0);
      }
    }
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type,
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type,
  .css-67l5gl:first-of-type,
  .css-67l5gl:last-of-type {
    border-radius: 0 !important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible {
    background: #fff !important;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: rgb(51, 51, 51) !important;
  }
}
