.second-navbar {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px -10px;
  margin-bottom: 8px;
  @media (max-width: 960px){
    padding-top: 10px !important;
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }
  @media (max-width: 767px){
    padding-top: 0 !important;
  }
  a {
    color: #333;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: transparent;
    font-family: "Jost";
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.4s;
    &:hover {
      font-family: "Jost Medium";
      background: rgba(255, 242, 64, 0.79);
    }
    @media (max-width: 960px){
      padding: 4px 0;
    }
  }
}
