.favorites {
  display: grid;
  @media (min-width: 1360px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  @media (min-width: 1023px) and (max-width: 1360px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 960px) and (max-width: 1023px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 767px) and (max-width: 960px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
}
