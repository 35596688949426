.brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  @media (max-width: 1025px){
    flex-wrap: nowrap;
    overflow-y: auto;
  }
  .slick-next {
    top: -20px;
    right: 0;
    width: 24px;
    height: 24px;
    &:hover {
      color: red !important;
    }
  }
  .slick-prev {
    top: -20px;
    left: auto;
    right: 35px;
    width: 24px;
    height: 24px;
    &:hover {
      color: red !important;
    }
  }

  .brand {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    min-width: 180px;
    height: 100px;
    padding: 12px;
    background: rgb(248, 248, 248);
    border: 1px solid rgb(229, 229, 229);
    overflow: hidden;
    transition: all 0.4s ease 0s;
    position: relative;
    @media (max-width: 767px) {
      width: 120px;
      min-width: 120px;
      height: 100px;
    }
    &::before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all 0.4s ease 0s;
      z-index: 100;
      background-color: rgba(4, 4, 4, 0);
      cursor: pointer;
    }
    &:hover::before {
      background-color: rgba(4, 4, 4, 0.3);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 0.4s ease 0s;
    }
    &:hover img {
      scale: 1.2;
    }
  }
}
.slick-center > div {
  text-align: center !important;
}
